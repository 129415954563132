
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { ErrorPage } from '@/templates';
import i18n from '@/i18n';
import { getLayout } from '@/components/Layout';
import { getCommonData } from '@/graphql/api';
import type { GetStaticProps } from 'next';
import type { AppCommonProps } from './_app';
const Page404 = () => <ErrorPage statusCode={404}/>;
const getStaticProps: GetStaticProps<AppCommonProps> = async ({ locale, }) => {
    const realLocale = locale ?? i18n.defaultLocale;
    const { footer, header } = await getCommonData({
        locale: realLocale,
        content: null,
        translations: i18n.locales
            .filter((l) => l !== realLocale)
            .map((l) => ({
            uri: `/${l}`,
            language: {
                code: l,
                slug: l,
            },
        })),
    });
    header.type = 'Default';
    return {
        props: {
            footer,
            header,
            seo: {
                title: '404',
                metaDesc: '404',
            },
        },
    };
};
Page404.getLayout = getLayout;
export default Page404;

    async function __Next_Translate__getStaticProps__195d70bad0f__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195d70bad0f__ as getStaticProps }
  